import withGuard from "../../utils/withGurard";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../quailty-ui/breadcrumb/Breadcrumb";
import { Form, Spinner, Table } from "react-bootstrap";
import { useFormik } from "formik";
import { Fragment, useEffect } from "react";
import fetchAllDepartments from "../../../store/department/actGetAllDepartments";
import actExtentionsFilter from "../../../store/KPIS/actExtentionsFilter";

const evaluatorNames = {
  13: "خلود العنزى",
  44: "هبـة القحطانى",
  51: "فـهد عـمر",
};
const Kpis = () => {
  const { data: allDepartments } = useSelector(
    (state) => state.departmentSlice
  );
  const { data, loading } = useSelector((state) => state.kpisSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllDepartments());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      department_id: "",
      date_from: "",
      date_to: "",
    },
    onSubmit: (values) => {
      dispatch(actExtentionsFilter(values));
    },
  });

  const totalMetrics = (metric) => {
    return data.reduce(
      (sum, row) =>
        sum +
        row.evaluators.reduce(
          (evaluatorSum, evaluator) =>
            evaluatorSum + parseInt(evaluator[metric] || "0", 10),
          0
        ),
      0
    );
  };
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة التقارير"
        dashboard="لوحة التحكم"
        breadcrumb="التقارير"
      />
      <div className="card">
        <div className="card-body">
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Form.Group className="form-group mb-3">
                    <Form.Label className="form-label">القسم</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="department_id"
                      onChange={formik.handleChange}
                      value={formik.values.department_id}
                      isInvalid={!!formik.errors.department_id}
                      // isValid={
                      //   formik.touched.department_id &&
                      //   !formik.errors.department_id
                      // }
                      onBlur={formik.handleBlur}
                    >
                      <option disabled value="">
                        اختر القسم
                      </option>
                      {allDepartments?.map((department) => (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.department_id}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>التاريخ من</Form.Label>
                    <Form.Control
                      type="date"
                      className="form-control  ps-2"
                      name="date_from"
                      onChange={formik.handleChange}
                      value={formik.values.date_from}
                      isInvalid={!!formik.errors.date_from}
                      // isValid={
                      //   formik.touched.date_from && !formik.errors.date_from
                      // }
                      onBlur={formik.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.date_from}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>التاريخ إلي</Form.Label>
                    <Form.Control
                      type="date"
                      className="form-control  ps-2"
                      name="date_to"
                      onChange={formik.handleChange}
                      value={formik.values.date_to}
                      isInvalid={!!formik.errors.date_to}
                      // isValid={formik.touched.date_to && !formik.errors.date_to}
                      onBlur={formik.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.date_to}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="col-md-6">
                {" "}
                <button
                  type="submit"
                  className="btn btn-primary btn-lg mt-4 w-100"
                  // disabled={loading || !(formik.isValid && formik.dirty)}
                >
                  {/* {loading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  <>أضف</>
                )} */}
                  بحث
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="card">
        <div className="card-body text-center">
          {/* Table */}

          {loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <div className="mt-4">
              <Table striped bordered hover responsive className="text-center">
                <thead>
                  <tr>
                    <th>Date</th>
                    {Object.keys(evaluatorNames).map((id) => (
                      <th key={id} colSpan={3}>
                        {evaluatorNames[id]}
                      </th>
                    ))}
                    <th colSpan={3}>Total</th>
                  </tr>
                  <tr>
                    <th></th>
                    {Object.keys(evaluatorNames).flatMap(() => [
                      <th key="calls">المكالمات</th>,
                      <th key="violations">المخالفات المؤكدة</th>,
                      <th key="warnings">التحذيرات</th>,
                    ])}
                    <th>المكالمات</th>
                    <th>المخالفات المؤكدة</th>
                    <th>التحذيرات</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => (
                    <tr key={row.id}>
                      <td>{row.date}</td>
                      {row.evaluators.map((evaluator) => (
                        <>
                          <td key={`${evaluator.evaluator_id}-calls`}>
                            {evaluator.calls}
                          </td>
                          <td key={`${evaluator.evaluator_id}-violations`}>
                            {evaluator.violations}
                          </td>
                          <td key={`${evaluator.evaluator_id}-warnings`}>
                            {evaluator.warnings}
                          </td>
                        </>
                      ))}
                      <td>
                        {row.evaluators.reduce(
                          (sum, evaluator) =>
                            sum + parseInt(evaluator.calls || "0", 10),
                          0
                        )}
                      </td>
                      <td>
                        {row.evaluators.reduce(
                          (sum, evaluator) =>
                            sum + parseInt(evaluator.violations || "0", 10),
                          0
                        )}
                      </td>
                      <td>
                        {row.evaluators.reduce(
                          (sum, evaluator) =>
                            sum + parseInt(evaluator.warnings || "0", 10),
                          0
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr className="font-weight-bold">
                    <td>Total</td>
                    {Object.keys(evaluatorNames).flatMap((id) => [
                      <td key={`${id}-calls-total`}>
                        {data.reduce(
                          (sum, row) =>
                            sum +
                            parseInt(
                              row.evaluators.find(
                                (evaluator) =>
                                  evaluator.evaluator_id.toString() === id
                              )?.calls || "0",
                              10
                            ),
                          0
                        )}
                      </td>,
                      <td key={`${id}-violations-total`}>
                        {data.reduce(
                          (sum, row) =>
                            sum +
                            parseInt(
                              row.evaluators.find(
                                (evaluator) =>
                                  evaluator.evaluator_id.toString() === id
                              )?.violations || "0",
                              10
                            ),
                          0
                        )}
                      </td>,
                      <td key={`${id}-warnings-total`}>
                        {data.reduce(
                          (sum, row) =>
                            sum +
                            parseInt(
                              row.evaluators.find(
                                (evaluator) =>
                                  evaluator.evaluator_id.toString() === id
                              )?.warnings || "0",
                              10
                            ),
                          0
                        )}
                      </td>,
                    ])}
                    <td>{totalMetrics("calls")}</td>
                    <td>{totalMetrics("violations")}</td>
                    <td>{totalMetrics("warnings")}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withGuard(Kpis);
