import React from "react";
import SalesEvaluation from "./SalesEvaluation";
import QualityEvaluation from "./QualityEvaluation";
import CoordinationEvaluation from "./CoordinationEvaluation";
import MultiEvaluation from "./MultiEvaluation";

const CallAction = ({ callDetails }) => {
  const callActionHandler = () => {
    if (callDetails?.department?.form_type === "SalesEvaluation") {
      return <SalesEvaluation />;
    } else if (callDetails?.department?.form_type === "QualityEvaluation") {
      return <QualityEvaluation />;
    } else if (
      callDetails?.department?.form_type === "CoordinationEvaluation"
    ) {
      return <CoordinationEvaluation />;
    } else if (callDetails?.department?.form_type === "MultiEvaluation") {
      return <MultiEvaluation />;
    }
  };
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mt-3 mb-4">تقييم المكالمة</h5>
        {callActionHandler()}
      </div>
    </div>
  );
};

export default CallAction;
