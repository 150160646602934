import { createSlice } from "@reduxjs/toolkit";
import fetchCallDetails from "./actGetCallDetails";
import violation from "./actViolation";
import getAllCalls from "./actGetAllCalls";
import getSingleCallDetails from "./actGetSingleCall";
import actFilterCalls from "./actFilterCalls";

const initialState = {
  call: null,
  error: null,
  loading: false,
  success: null,
  callDetails: null,
  callAfterEvaluation: null,
  data: [],
  singlecall: null,
};

const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.call = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetData: (state) => {
      state.call = null;
      state.callDetails = null;
    },
    success: (state, action) => {
      state.success = action.payload;
    },
    resetAllData: (state) => {
      state.data = [];
    },
    resetSingleCall: (state) => {
      state.singlecall = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== get data from server ==========================
    builder.addCase(fetchCallDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCallDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.callDetails = action.payload;
    });
    builder.addCase(fetchCallDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== get data from server ==========================
    builder.addCase(violation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(violation.fulfilled, (state, action) => {
      state.loading = false;
      state.callAfterEvaluation = action.payload;
    });
    builder.addCase(violation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== get all calls from server ==========================
    builder.addCase(getAllCalls.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllCalls.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getAllCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== get filter calls from server ==========================
    builder.addCase(actFilterCalls.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actFilterCalls.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(actFilterCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== get data from server ==========================
    builder.addCase(getSingleCallDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSingleCallDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.singlecall = action.payload;
    });
    builder.addCase(getSingleCallDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
export const {
  setData,
  setError,
  resetData,
  success,
  resetAllData,
  resetSingleCall,
} = callSlice.actions;
export default callSlice.reducer;
