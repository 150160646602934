import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const actExtentionsFilter = createAsyncThunk(
  "kpis/actExtentionsFilter",
  async (el, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `extensions/filter`,
        data: el,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default actExtentionsFilter;
