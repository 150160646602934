import React from "react";
import CallActionModal from "../callAction/CallActionModal";

const CheckboxMultiEvaluation = ({
  violation,
  evaluator_note,
  evaluator_id,
  violations,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row alig n-items-start">
          <div className="col-8">
            <h5 className="card-title p-2 fw-semibold d-flex align-items-center">
              تفاصيل تقييم المراقب
            </h5>
            <p>{evaluator_id}</p>
          </div>

          <div className="col-4">
            <div className="d-flex justify-content-end">
              <div className="text-white bg-danger rounded p-2 d-flex align-items-center justify-content-center">
                مخالفة
              </div>
              {localStorage.getItem("role") === "System Admin" && (
                <CallActionModal violations={violations} />
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <h6 className="fw-semibold mb-3">المخالفات :</h6>
          </div>
          {violation.intro === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">
                  افتتاحية المكالمة (اسم الشركة و الموظف)
                </p>
              </div>
            </div>
          )}
          {violation.record_mention === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center"></span>
                <p className="fs-3 mb-0">ذكر تسجيل المكالمة لغرض الجودة</p>
              </div>
            </div>
          )}
          {violation.confrim_data === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">
                  التاكد من اسم العميل و المدينة و الحي
                </p>
              </div>
            </div>
          )}
          {violation.cur_last_maintenance === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">
                  توضيح الصيانة الحالية و اخر صيانة تمت
                </p>
              </div>
            </div>
          )}
          {violation.maintenance_price === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">توضيح سعر الصيانة </p>
              </div>
            </div>
          )}
          {violation.date_data_check === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">مراجعة الموعد و البيانات مع العميل </p>
              </div>
            </div>
          )}
          {violation.mention_msg === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">
                  توضيح وصول رسالة تأكيد الموعد خلال 48 ساعة قبل الموعد عن طريق
                  الواتس اب
                </p>
              </div>
            </div>
          )}
          {violation.other_services_mention === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">سؤال العميل عن اي خدمة اخرى </p>
              </div>
            </div>
          )}
          {violation.social_media_rate === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">تقيم الخدمة عبرالسوشل ميديا</p>
              </div>
            </div>
          )}
          {violation.outro === 1 && (
            <div className="col-md-10 offset-1 mb-2">
              <div className="d-flex align-items-center pb-1">
                <span className="me-2 rounded-circle bg-light-primary round-20 d-flex align-items-center justify-content-center">
                  <i className="ti ti-arrow-down-right text-danger"></i>
                </span>
                <p className="fs-3 mb-0">الختامية</p>
              </div>
            </div>
          )}

          <div className="col-md-12 mt-2">
            <h6 className="fw-semibold mb-3"> الملاحظات :</h6>
          </div>
          <div className="col-md-10 offset-1">
            <p className="fs-3 mb-3">
              {evaluator_note ? evaluator_note : "لا يوجد ملاحظات"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckboxMultiEvaluation;
