import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SalesEvaluation from "./SalesEvaluation";
import QualityEvaluation from "./QualityEvaluation";
import CoordinationEvaluation from "./CoordinationEvaluation";
import MultiEvaluation from "./MultiEvaluation";
import { useParams } from "react-router-dom";

function CallActionModal({ violations }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { id } = useParams();

  const callActionHandler = () => {
    if (violations?.call?.department?.form_type === "SalesEvaluation") {
      return (
        <SalesEvaluation
          evaluation_id={id}
          form_type={violations?.call?.department?.form_type}
          handleClose={handleClose}
        />
      );
    } else if (
      violations?.call?.department?.form_type === "QualityEvaluation"
    ) {
      return (
        <QualityEvaluation
          evaluation_id={id}
          form_type={violations?.call?.department?.form_type}
          handleClose={handleClose}
        />
      );
    } else if (
      violations?.call?.department?.form_type === "CoordinationEvaluation"
    ) {
      return (
        <CoordinationEvaluation
          evaluation_id={id}
          form_type={violations?.call?.department?.form_type}
          handleClose={handleClose}
        />
      );
    } else if (violations?.call?.department?.form_type === "MultiEvaluation") {
      return (
        <MultiEvaluation
          evaluation_id={id}
          form_type={violations?.call?.department?.form_type}
          handleClose={handleClose}
          multi_evaluations={violations?.multi_evaluations}
        />
      );
    }
  };
  return (
    <>
      <Button variant="success" className="ms-2" onClick={handleShow}>
        تعديل
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل تقييم المراقب</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-5">{callActionHandler()}</div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CallActionModal;
