import React from "react";
import CheckboxQualityEvaluation from "../checkbox/CheckboxQualityEvaluation";
import CheckboxSalesEvaluation from "../checkbox/CheckboxSalesEvaluation";
import CheckboxCoordinationEvaluation from "../checkbox/CheckboxCoordinationEvaluation";
import CheckboxMultiEvaluation from "../checkbox/CheckboxMultiEvaluation";

const EvaluatorUnvalidCallDetails = ({ violation }) => {
  const checkBoxHandler = () => {
    if (violation?.quality_evaluations) {
      return (
        <CheckboxQualityEvaluation
          evaluator_note={violation.evaluator_note}
          evaluator_id={violation.evaluator_id}
          violation={violation.quality_evaluations}
          violations={violation}
        />
      );
    } else if (
      violation?.sales_evaluations ||
      violation === "SalesEvaluation"
    ) {
      return (
        <CheckboxSalesEvaluation
          evaluator_note={violation.evaluator_note}
          evaluator_id={violation.evaluator_id}
          violation={violation.sales_evaluations}
          violations={violation}
        />
      );
    } else if (violation?.coordination_evaluations) {
      return (
        <CheckboxCoordinationEvaluation
          evaluator_note={violation.evaluator_note}
          evaluator_id={violation.evaluator_id}
          violation={violation.coordination_evaluations}
          violations={violation}
        />
      );
    } else if (violation?.multi_evaluations) {
      return (
        <CheckboxMultiEvaluation
          evaluator_note={violation.evaluator_note}
          evaluator_id={violation.evaluator_id}
          violation={violation.multi_evaluations}
          violations={violation}
        />
      );
    }
  };
  return <>{checkBoxHandler()}</>;
};

export default EvaluatorUnvalidCallDetails;
