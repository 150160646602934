import { createSlice } from "@reduxjs/toolkit";
import getAllViolationCalls from "./actGetAllViolationCalls";
import fetchSingleViolation from "./actGetsingleViolation";
import getAllNotViolationCalls from "./actGetAllNotViolationCalls";
import sendSupervisorEvaluation from "./actSendSupervisorEvaluation";

const initialState = {
  data: [],
  error: null,
  loading: false,
  singleViolation: null,
};

const supervisorSlice = createSlice({
  name: "supervisor",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
    singleViolationCleanUp: (state) => {
      state.singleViolation = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== get data from server ==========================
    builder.addCase(getAllViolationCalls.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllViolationCalls.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getAllViolationCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== read data from server ==========================
    builder.addCase(fetchSingleViolation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSingleViolation.fulfilled, (state, action) => {
      state.loading = false;
      state.singleViolation = action.payload;
    });
    builder.addCase(fetchSingleViolation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== get data from server ==========================
    builder.addCase(getAllNotViolationCalls.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllNotViolationCalls.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getAllNotViolationCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== get data from server ==========================
    builder.addCase(sendSupervisorEvaluation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(sendSupervisorEvaluation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetData, singleViolationCleanUp } = supervisorSlice.actions;
export default supervisorSlice.reducer;
