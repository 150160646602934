import { createSlice } from "@reduxjs/toolkit";
import actExtentionsFilter from "./actExtentionsFilter";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const kpisSlice = createSlice({
  name: "kpis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ========================== get data from server ==========================
    builder.addCase(actExtentionsFilter.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actExtentionsFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(actExtentionsFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default kpisSlice.reducer;
