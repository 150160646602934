/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumb = ({ breadcrumbHeading, dashboard, breadcrumb, children }) => {
  return (
    <div className=" shadow-none position-relative overflow-hidden ">
      <div className="card-body  py-3">
        <div className="row align-items-center">
          <div className="col-9">
            <h4 className="fw-semibold mb-8">{breadcrumbHeading}</h4>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink className="text-muted " to="/dashboard">
                    {dashboard}
                  </NavLink>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  {breadcrumb}
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-3">
            <div className="text-start">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
