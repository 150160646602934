import React from "react";
import CallDetails from "../../../quailty-ui/callDetails/CallDetails";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import EvaluatorvalidCallDetails from "../../../quailty-ui/callDetails/EvaluatorvalidCallDetails";
import withGuard from "../../../utils/withGurard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import fetchSingleViolation from "../../../../store/supervisor/actGetsingleViolation";
import Loading from "../../../quailty-ui/Loading_page/Loading";
import { singleViolationCleanUp } from "../../../../store/supervisor/supervisorSlice";

const SupervisorValidCallDetailsPage = () => {
  const { singleViolation, loading } = useSelector(
    (state) => state.supervisorSlice
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleViolation(id));
    return () => {
      dispatch(singleViolationCleanUp());
    };
  }, [dispatch, id]);
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة تفاصيل المكالمة"
        dashboard="لوحة التحكم"
        breadcrumb="تفاصيل تقييم المكالمة"
      />
      <Loading loading={loading}>
        <CallDetails CallDetails={singleViolation} />
        <div className="row">
          <div className="col-md-6 offset-3">
            <EvaluatorvalidCallDetails violation={singleViolation} />
          </div>
        </div>
      </Loading>
    </>
  );
};

export default withGuard(SupervisorValidCallDetailsPage);
