import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const actFilterCalls = createAsyncThunk(
  "call/actFilterCalls",
  async (el, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/evaluations/filter`,
        data: el,
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export default actFilterCalls;
